.DayView {
    display: flex;
    padding: 2px 10px;
    gap: 10px;
    position: relative;
    align-items: center;
}

.DayView-dateOfMonth {
    min-width: 2.2em;
    text-align: center;
}

.DayView-dateOfMonth span {
    border-radius: 50%;
    width: 2em;
    height: 2em;
    display: grid;
    place-content: center;
    margin: -3px;
}

.DayView-today .DayView-dateOfMonth span {
    color: white;
    background: #1a73e8;
}

.DayView-weekend {
    background-color: var(--light-gray);
}

.DayView-events {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 4px;
}
