@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap');

.App {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-scrollContainer {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
