
.CalendarChooser {
    display: flex;
    column-gap: 5px;
    row-gap: 9px;
    max-width: 500px;
    flex-wrap: wrap;
}

.CalendarChooser-calendar,
.CalendarChooser-calendar label {
    cursor: pointer;
}

.CalendarChooser-calendar input[type=checkbox] {
    display: none;
}

.CalendarChooser-name {
    padding: 2px 4px;
    border-radius: 3px;
    white-space: nowrap;
    user-select: none;
}
