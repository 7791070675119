
.Header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    border-bottom: 1px solid #ccc;
}

.Header button {
    border: 1px solid #ccc;
    background: white;
    color: #444;
    border-radius: 999px;
    padding: 5px 10px;
    cursor: pointer;
}

@media (hover: hover) {
    .Header button:hover {
        background: #f8f8f8;
    }
}

.Header-auth {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
