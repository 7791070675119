
.EventView-separator {
    color: #888;
    min-width: 1em;
}

.EventView {
    padding: 2px 5px;
    border-radius: 3px;
    text-decoration: none;
    white-space: nowrap;
}

.EventView-timestamp {
    margin-right: 0.3em;
}
