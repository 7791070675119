.MultiDayEventView {
    display: flex;
    gap: 5px;
}

.MultiDayEventView-eventArea,
.MultiDayEventView-brace {
    display: flex;
    flex-direction: column;
}

.MultiDayEventView-eventArea {
    flex-grow: 1;
    align-items: flex-end;
}

.MultiDayEventView-event {
    padding: 4px 8px;
    border-radius: 3px;
    text-decoration: none;
}

.MultiDayEventView-top,
.MultiDayEventView-bottom {
    height: 15px;
}

.MultiDayEventView-aboveStretch,
.MultiDayEventView-belowStretch {
    flex-grow: 1; /* overwritten in code */
}

.MultiDayEventView-middle {
    height: 15px;
    max-height: 15px;
}

.MultiDayEventView-eventArea .MultiDayEventView-middle {
    display: grid;
    place-content: center;
}

.MultiDayEventView-brace {
    width: 30px;
    background-position: right;
    background-repeat: no-repeat;
}

.MultiDayEventView-brace .MultiDayEventView-top {
    background-image: url(bracket-top.svg);
}

.MultiDayEventView-brace .MultiDayEventView-aboveStretch,
.MultiDayEventView-brace .MultiDayEventView-belowStretch {
    background-image: url(bracket-stretch.svg);
    background-repeat: repeat-y;
}

.MultiDayEventView-brace .MultiDayEventView-middle {
    height: 15px;
    background-image: url(bracket-middle.svg);
}

.MultiDayEventView-brace .MultiDayEventView-bottom {
    background-image: url(bracket-bottom.svg);
}
