
.MonthView {
    position: relative;
}

.MonthView span {
    text-align: center;
    height: 100%;
    font-size: 130px;
    font-weight: 100;
    color: var(--light-gray);
    letter-spacing: -5px;
    writing-mode: vertical-rl;
    transform: scale(-1);
    padding-left: 30px;
}

.MonthView-even span {
    background-color: var(--light-gray);
    color: white;
}
